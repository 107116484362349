html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: transform 0.2s;
}
html {
  font-size: 62.5%; /* font-size 1em = 10px on default browser settings */
  height: 100%;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f6;
  color: #333333;
  /* See https://stackoverflow.com/questions/59732074/disable-double-tap-zoom-on-safari-ios-13 */
  touch-action: manipulation;
}
#root {
  height: 100%;
}
#toast-root {
  z-index: 30000; /* Super high :p */
}
.ant-select-multiple .ant-select-selection-item {
  min-height: 24px;
  height: auto !important;
}
.ant-select-multiple .ant-select-selection-item-content {
  white-space: normal !important;
}
.ant-select-dropdown .ant-select-item-option-content {
  white-space: normal !important;
}
.ant-select-dropdown,
.ant-dropdown {
  z-index: 10003;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
h3,
h4:not(#general-settings .ant-typography) {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 500;
}
h3 {
  color: #333333;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}
a {
  color: #2b7c96;
  text-decoration: none;
}
ul {
  list-style: none;
}

p {
  font-weight: 300;
  line-height: 2.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  font-family: inherit;
}
@media (max-width: 681px) {
  body {
    background: #ffffff;
  }
  /* Hide hubspot on mobile */
  body #hubspot-messages-iframe-container {
    width: 0 !important;
    height: 0 !important;
  }
}

#hubspot-messages-iframe-container .reagan--widget-loaded {
  box-shadow: none !important;
}

.ant-select-dropdown {
  z-index: 100001;
}

.ant-drawer .ant-drawer-header .ant-drawer-header-title h3 {
  text-align: unset;
}

.ant-picker-dropdown .ant-picker-time-panel-column::after {
  height: unset !important;
}

.treeSelectLocations div.ant-select-selection-overflow {
  max-height: unset !important;
}

.ant-form label {
  font-size: 16px !important;
}

.ant-message {
  bottom: 20px !important;
  top: initial !important;
}